import React from 'react'
import { Box, Button, Grid, Paper, Stack} from '@mui/material'
import { Form,Row,Col,Container ,input } from 'react-bootstrap'
import Social_media from './Social_media';
import Menu from './Menu';
import { Fade } from 'react-reveal';
const Contact = () => {
  return (
    <Row className='justify-content-center '>
      <div className='titel'>تواصل معنا</div>
    <Col  xs={1} md={1} lg={1} className='align-items-center'>
        <div className='media_des'>
        <Social_media/>
        </div>        
   </Col>

   <Col item xs={12} md={10} lg={10}>
  <Fade top>
    <section id="contact" className="contact">
      <Container>

        <Row className='justify-content-center'>
          <Col lg={3} md={6} xs={6}>
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>عنواننا</h3>
              <p>الرياض _ حي الإذدهار _ طريق عثمان بن عفان</p>
            </div>
          </Col>

          <Col lg={3} md={6} xs={6}>
            <div className="info-box  mb-4">
              <i className="bx bx-envelope"></i>
              <h3>مراسلتنا عبر البريد الإلكتروني</h3>
              <p>  info@ssceng.com </p>
            </div>
          </Col>

          <Col lg={3} md={6} xs={6}>
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call"></i>
              <h3>اتصل بنا</h3>
              <p> 508767975 966+</p>
            </div>
          </Col>

        </Row>

        <Row>

       

          <Col class="col-lg-6">
            <Form >
              <Row>
              <Col lg={6} md={6} xs={6}>
         <Form.Group controlId="formFile" className="mb-3">
         <Form.Label> الاسم</Form.Label>
         <Form.Control type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
         </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={6}>
                <Form.Group controlId="formFile" className="mb-3">
         <Form.Label > البريد الالكتروني</Form.Label>
         <Form.Control type="email" placeholder="" aria-label="Disabled input example" size="lg"/>
         </Form.Group>
                </Col>
              </Row>
              <div className="form-group mt-3">
              <Form.Label >الموضوع</Form.Label>
              <Form.Control type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
              </div>
              <div className="form-group mt-3">
              <Form.Group controlId="formFile" className="mb-3">
         <Form.Label> الرسالة</Form.Label>
         <Form.Control  as="textarea" rows={3} placeholder="" aria-label="Disabled input example" size="lg"/>
       </Form.Group>
              </div>

              <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className="mt-3">
   
   <Button variant="contained" size='lg' className='btn-grad m-2'>
     ارسال
   </Button>
   
   </Grid>
            </Form>
          </Col>

          <Col lg={6} md={6} xs={6}>
            {/* <iframe class="mb-4 mb-lg-0" src="https://goo.gl/maps/EunJYdzShJBegK4U7" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.3331028461794!2d46.7105704137602!3d24.784044891546827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee44702a3df51%3A0xb54a3a9372de40c3!2z2YXZg9iq2Kgg2KfZhNmF2YbZiNin2YQg2YTZhNin2LPYqti02KfYsdin2Kog2KfZhNmH2YbYr9iz2YrYqQ!5e0!3m2!1sar!2ssa!4v1697644158178!5m2!1sar!2ssa"  width="100%" height="384px" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3538.4183009586577!2d41.71369142393994!3d27.518460733796104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1576478a641ea2f7%3A0x71ad190a792c4988!2z2K3ZiiDYp9mE2YbYudin2YU!5e0!3m2!1sar!2ssa!4v1692167575337!5m2!1sar!2ssa" ></iframe> */}
          </Col>
        </Row>

      </Container>
    </section>

              



               </Fade>
  </Col>

  
  <Col  xs={1} md={1} lg={1} className='align-items-center'>
      <div className='menu_des'>
               <Menu/>
               </div>
      </Col>
</Row>  





  )
}

export default Contact

